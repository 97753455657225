import axios from "axios";
import qs from "qs";
import store from "@/store";
// import router from "@/router";
import { LOGOUT, PURGE_AUTH } from "@/store/mutation.types";
import { clearToken } from "@/utils/util";
import { showToast } from "vant";
import router from "@/router";

const transferApiList = ["/openai/v1", "/leonardo/api", "/claude/v1", "/mj", "/replicate"];

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // 设置全局的baseURL
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

    // 全局将对象数据转换为queryString数据, 这样提交到后台的数据是表单, 而不是json
    // axios 会根据数据自动设置 header 的 Content-type, 无需手动设置此header
    // 取消全局设置, 因为会和文件上传冲突, 在调用post时直接使用 qs.stringify() 即可
    // axios.defaults.transformRequest = [
    //   (data) => {
    //     return qs.stringify(data);
    //   },
    // ];

    // 允许携带 cookie, 以实现 session 功能
    // axios.defaults.withCredentials = true;

    // 添加请求拦截器, 在 header 里携带 access_token 给后端
    axios.interceptors.request.use(
      function (config) {
        config.headers = {
          ...config.headers,
          "Access-Token": store.getters.currentUser ? store.getters.currentUser?.access_token : "",
        };
        //自定义接口头
        const qs = new URLSearchParams(config.data);
        const customApiBase = qs.get("customApiBase");
        if (customApiBase) {
          config.baseURL = process.env.VUE_APP_OPENAI_API_BASE_URL;
        }

        const isTransferApi = transferApiList.some((e) => config.url.startsWith(e));
        if (isTransferApi) {
          config.baseURL = process.env.VUE_APP_OPENAI_API_BASE_URL;
          if (config.method == "post") {
            config.headers["app-id"] = sessionStorage.getItem("app_id");
          } else if (config.method == "get") {
            config.headers["app-id"] = config.params.app_id;
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // 添加响应拦截, 未登录的情况跳转到登录页面
    axios.interceptors.response.use(
      function (response) {
        // 2xx
        return response.data;
      },
      function (error) {
        // not 2xx
        if (error.response.status == 400 || error.response.status == 500) {
          showToast(error.response?.data?.msg ?? error.response?.data?.message ?? "服务异常");
        }
        if (error.response.status == 401) {
          showToast(error.response?.data?.msg ?? error.response?.data?.message ?? "访问受限");
          clearToken();
          store.commit(PURGE_AUTH);
          const { pathname, search } = window.location;
          if (pathname === "/sso/login") {
            return;
          }
          const redirect_url = encodeURIComponent(`${pathname}${search}`);
          router.replace({
            name: "Login",
            query: {
              redirect_url,
            },
          });
        }

        return Promise.reject(error.response);
      }
    );
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(resource, qs.stringify({ __from: "mobile", ...params }));
  },

  payloadAipost(resource, params) {
    return axios.post(resource, { ...params });
  },

  openAipost(resource, params) {
    return axios.post(resource, { ...params });
  },

  leonardoAipost(resource, params) {
    return axios.post(resource, { ...params });
  },

  get(resource, params) {
    return axios.get(resource, {
      params: {
        ...params,
        __from: "mobile",
      },
    });
  },

  /**
   * Set the POST HTTP request for upload file
   * @param resource
   * @param formData
   * @returns {*}
   */
  upload(resource, formData) {
    return axios.post(resource, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

ApiService.init();

export default ApiService;
