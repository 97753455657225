const replicateRouter = [
  {
    path: "/rembg",
    name: "Rembg",
    meta: {
      title: "AI抠图",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/rembg/index.vue"),
  },
  {
    path: "/cartoonify",
    name: "Cartoonify",
    meta: {
      title: "照片卡通化",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/cartoonify/index.vue"),
  },
  {
    path: "/opensora",
    name: "OpenSora",
    meta: {
      title: "Open Sora",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/opensora/index.vue"),
  },
  {
    path: "/gfpgan",
    name: "GFPGan",
    meta: {
      title: "老照片修复",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/gfpgan/index.vue"),
  },
  {
    path: "/gfpgan-settings",
    name: "GFPGanSettings",
    meta: {
      title: "老照片修复",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/gfpgan/settings.vue"),
  },
  {
    path: "/codeformer",
    name: "Codeformer",
    meta: {
      title: "老照片修复V2",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/codeformer/index.vue"),
  },
  {
    path: "/codeformer-settings",
    name: "CodeformerSettings",
    meta: {
      title: "老照片修复V2",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/codeformer/settings.vue"),
  },
  {
    path: "/svd",
    name: "Svd",
    meta: {
      title: "视频扩散",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/svd/index.vue"),
  },
  {
    path: "/svd-settings",
    name: "SvdSettings",
    meta: {
      title: "视频扩散",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/svd/settings.vue"),
  },
  {
    path: "/flux-trainer",
    name: "FluxLoraTrainer",
    meta: {
      title: "Flux Lora 训练",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/flux-trainer/index.vue"),
  },
  {
    path: "/flux-trainer-settings",
    name: "FluxLoraTrainerSettings",
    meta: {
      title: "Flux Lora 训练",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/flux-trainer/settings.vue"),
  },
  {
    path: "/photomaker",
    name: "PhotoMaker",
    meta: {
      title: "PhotoMaker",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/photomaker/index.vue"),
  },
  {
    path: "/photomaker-settings",
    name: "PhotoMakerSettings",
    meta: {
      title: "PhotoMaker",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/photomaker/settings.vue"),
  },
  {
    path: "/photomaker-style",
    name: "PhotomakerStyle",
    meta: {
      title: "Photomaker Style",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/photomaker-style/index.vue"),
  },
  {
    path: "/photomaker-style-settings",
    name: "PhotomakerStyleSettings",
    meta: {
      title: "Photomaker Style",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/photomaker-style/settings.vue"),
  },
  {
    path: "/profilepics",
    name: "ProfilePics",
    meta: {
      title: "ProfilePics",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/profilepics/index.vue"),
  },
  {
    path: "/profilepics-settings",
    name: "ProfilePicsSettings",
    meta: {
      title: "ProfilePics",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/profilepics/settings.vue"),
  },
  {
    path: "/ad-inpaint",
    name: "AdInpaint",
    meta: {
      title: "Ad Inpaint",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/ad-inpaint/index.vue"),
  },
  {
    path: "/ad-inpaint-settings",
    name: "AdInpaintSettings",
    meta: {
      title: "AdInpaint",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/ad-inpaint/settings.vue"),
  },
  {
    path: "/instantid",
    name: "InstantID",
    meta: {
      title: "InstantID",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/instantid/index.vue"),
  },
  {
    path: "/instantid-settings",
    name: "InstantIDSettings",
    meta: {
      title: "InstantID",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/instantid/settings.vue"),
  },
  {
    path: "/face-to-sticker",
    name: "FaceSticker",
    meta: {
      title: "人脸贴纸生成器",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/face-to-sticker/index.vue"),
  },
  {
    path: "/facetosticker-settings",
    name: "FaceStickerSettings",
    meta: {
      title: "人脸贴纸生成器",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/face-to-sticker/settings.vue"),
  },
  {
    path: "/musicgen",
    name: "MusicGen",
    meta: {
      title: "音乐生成",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/musicgen/index.vue"),
  },
  {
    path: "/musicgen-settings",
    name: "MusicGenSettings",
    meta: {
      title: "音乐生成",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/musicgen/settings.vue"),
  },
  {
    path: "/sadtalker",
    name: "SadTalker",
    meta: {
      title: "SadTalker",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/sadtalker/index.vue"),
  },
  {
    path: "/sadtalker-settings",
    name: "SadTalkerSettings",
    meta: {
      title: "SadTalker",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/sadtalker/settings.vue"),
  },
  {
    path: "/oot-diffusion",
    name: "OotDiffusion",
    meta: {
      title: "OotDiffusion",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/oot-diffusion/index.vue"),
  },
  {
    path: "/deepfake",
    name: "Deepfake",
    meta: {
      title: "deepfake",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/deepfake/index.vue"),
  },
  {
    path: "/llama3-8b",
    name: "Llama38b",
    meta: {
      title: "Llama3 8b",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/llama3/index.vue"),
  },
  {
    path: "/llama3-70b",
    name: "Llama370b",
    meta: {
      title: "Llama3 70b",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/llama3/index.vue"),
  },
  {
    // 8b, 70b 共用设置
    path: "/llama3/settings",
    name: "Llama3Settings",
    meta: {
      title: "Llama3",
      needLogin: true,
      needTitle: false,
    },
    component: () => import("@/views/apps/replicate/llama3/settings.vue"),
  },
];

export default replicateRouter;
